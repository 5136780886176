import _ from "lodash";
import {
  PlayerBundle,
  PlayerBundle__AccountType,
  PrettyPlayerBundle,
  PlayerBundleId,
  TeamId,
  PlayerId,
  PlayerBundle__LinkedPlayers__item
} from "@ollie-sports/models";
import { getUniversalHelpers } from "../helpers";
import { translate } from "@ollie-sports/i18n";
import { BatchTask } from "@ollie-sports/firebase";
import jsonStableStringify from "json-stable-stringify";

export function extractSelfAccountIdFromPlayerBundle(p: { bundle: PlayerBundle }): string | undefined {
  return Object.keys(p.bundle.managingAccounts ?? {}).find(
    id => p.bundle.managingAccounts?.[id]?.type === PlayerBundle__AccountType.selfAthlete
  );
}

export function extractUniqueActiveTeamIdsPlayerBundle(p: { bundle: PlayerBundle }): string[] {
  let teams: string[] = [];
  let activeLinkedPlayers = Object.values(p.bundle.derived.linkedPlayers).forEach(linkedPlayer => {
    if (linkedPlayer.status === "active") {
      teams.push(linkedPlayer.teamId);
    }
  });
  return teams;
}

export function extractUniqueTeamIdsPlayerBundle(p: { bundle: PlayerBundle }): string[] {
  let teamIds: string[] = [];
  let activeLinkedPlayers = Object.values(p.bundle.derived.linkedPlayers).forEach(linkedPlayer => {
    teamIds.push(linkedPlayer.teamId);
  });
  return _.uniq(teamIds);
}

export function extractUniquePlayerIdsFromPlayerBundles(p: {
  currentAccountId: string;
  bundles: (PlayerBundle | PrettyPlayerBundle)[];
}): { playerId: string; type: PlayerBundle__AccountType }[] {
  const bundles = p.bundles.map(a => ("playerBundle" in a ? a.playerBundle : a));
  let result: { playerId: string; type: PlayerBundle__AccountType }[] = [];
  bundles.forEach(bundle => {
    if (bundle.managingAccounts) {
      let playerType: PlayerBundle__AccountType =
        bundle.managingAccounts[p.currentAccountId]?.type || PlayerBundle__AccountType.guardian;
      let playerIds = Object.keys(bundle.derived.linkedPlayers);
      playerIds.forEach(pid => {
        result.push({ playerId: pid, type: playerType });
      });
    }
  });
  return result;
}

export function extractGuardianAccountIdsFromPlayerBundle(p: { bundle: PlayerBundle }): string[] {
  return Object.keys(p.bundle.managingAccounts ?? {}).filter(
    id => p.bundle.managingAccounts?.[id]?.type === PlayerBundle__AccountType.guardian
  );
}

export function extractUniqueGuardianAccountIdsFromPlayerBundles(p: {
  bundles: PlayerBundle[];
  selfAccountId: string;
}): string[] {
  let pendingGuardianAccounts: string[] = [];
  for (let i = 0; i < p.bundles.length; i++) {
    const bundle = p.bundles[i];
    const selfAccountId = extractSelfAccountIdFromPlayerBundle({ bundle });
    if (selfAccountId) {
      continue;
    }
    pendingGuardianAccounts = [...pendingGuardianAccounts, ...extractGuardianAccountIdsFromPlayerBundle({ bundle })];
  }

  return _.uniq(pendingGuardianAccounts);
}

export async function canMergePlayerBundles(p: {
  pb1: PlayerBundle;
  pb2: PlayerBundle;
  locale: string;
}): Promise<{ status: "allowed" } | { status: "not_allowed"; reason: string }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let selfAccountId1 = extractSelfAccountIdFromPlayerBundle({ bundle: p.pb1 });
  let selfAccountId2 = extractSelfAccountIdFromPlayerBundle({ bundle: p.pb2 });

  if (selfAccountId1 !== undefined && selfAccountId1 === selfAccountId2) {
    // Since they both self claimed by the same person we allow
    return { status: "allowed" };
  }

  if ((selfAccountId1 === undefined && selfAccountId2) || (selfAccountId2 === undefined && selfAccountId1)) {
    const selfAccountId = selfAccountId1 ? selfAccountId1 : selfAccountId2;

    const selfAccount = await h.Account.getDoc(selfAccountId as string);
    const selfAccountName = `${selfAccount?.firstName} ${selfAccount?.lastName}`;

    return {
      status: "not_allowed",
      reason: translate(
        {
          defaultMessage: `One player has already been claimed by {selfAccountName}. While the other player only has guardians. Merge not allowed. If you wish to merge them please have {selfAccountName} claim both of them before merging.`,
          serverLocale: p.locale
        },
        { selfAccountName }
      )
    };
  }

  if (!!selfAccountId1 && !!selfAccountId2 && selfAccountId1 !== selfAccountId2) {
    return {
      status: "not_allowed",
      reason: translate({
        defaultMessage: `Both players have been claimed by diffeent accounts. Please have one of the players unlink before trying to merge.`,
        serverLocale: p.locale
      })
    };
  }

  const playerBundlesOnlyHaveGuardians = !selfAccountId1 && !selfAccountId2;
  if (playerBundlesOnlyHaveGuardians) {
    const firstNamesMatch = p.pb1.virtualAthleteAccount.firstName.trim() === p.pb2.virtualAthleteAccount.firstName.trim();
    const lastNamesMatch = p.pb1.virtualAthleteAccount.lastName.trim() === p.pb2.virtualAthleteAccount.lastName.trim();

    if (firstNamesMatch && lastNamesMatch) {
      return { status: "allowed" };
    } else {
      return {
        status: "not_allowed",
        reason: translate({
          defaultMessage: `The first and last names of the players do not match. If you wish to merge them update their names to match and try again.`,
          serverLocale: p.locale
        })
      };
    }
  }

  return {
    status: "not_allowed",
    reason: translate({
      defaultMessage: `Unable to merge players. If this problem persist please contact support.`,
      serverLocale: p.locale
    })
  };
  // SERVER_ONLY_TOGGLE
}

export async function getBatchTasksToUpdateDerivedForPlayerBundle(p: { playerBundleId: PlayerBundleId }): Promise<BatchTask[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const tasks: BatchTask[] = [];

  let pendingLinkedPlayers: PlayerBundle["derived"]["linkedPlayers"] = {};

  const profile = await h.PlayerBundle.getDoc(p.playerBundleId);
  if (!profile) {
    throw new Error("Unable to find player bundle! " + p.playerBundleId);
  }
  const linkedPlayerObjects = (await h.Player.query({ where: [{ linkedPlayerBundleId: ["==", p.playerBundleId] }] })).docs;
  if (linkedPlayerObjects.length > 0) {
    pendingLinkedPlayers = linkedPlayerObjects.reduce((acc: typeof pendingLinkedPlayers, val) => {
      const temp: PlayerBundle__LinkedPlayers__item = {
        teamId: val.teamId,
        status: val.deletedAtMS === 0 ? "active" : "inactive",
        guestPlayerExirationMS: val.guestPlayerExirationMS ? val.guestPlayerExirationMS : undefined
      };
      acc[val.id] = temp;
      return acc;
    }, {});
  }

  if (jsonStableStringify(profile.derived.linkedPlayers) !== jsonStableStringify(pendingLinkedPlayers)) {
    tasks.push(
      await h.PlayerBundle.setPath(
        {
          id: p.playerBundleId,
          pathObj: { derived: { linkedPlayers: true } },
          value: { derived: { linkedPlayers: pendingLinkedPlayers } }
        },
        { returnBatchTask: true }
      )
    );
  }

  const activeLinkedTeamIds = _(Object.keys(pendingLinkedPlayers))
    .map(pbid => pendingLinkedPlayers[pbid])
    .filter(a => a.status === "active")
    .map(a => a.teamId)
    .value();

  const activeLinkedTeams = await h.Team.getDocs(activeLinkedTeamIds).then(a => _.compact(a));
  const activeLinkedOrgIds = _(activeLinkedTeams)
    .map(a => a.orgId)
    .compact()
    .uniq()
    .value();

  const pendingActiveOrgs = _(activeLinkedOrgIds)
    .keyBy(a => a)
    .mapValues(() => true as const)
    .value();

  if (jsonStableStringify(pendingActiveOrgs) !== jsonStableStringify(profile.derived.activeLinkedOrgs || {})) {
    tasks.push(
      await h.PlayerBundle.setPath(
        {
          id: p.playerBundleId,
          pathObj: { derived: { activeLinkedOrgs: true } },
          value: { derived: { activeLinkedOrgs: pendingActiveOrgs } }
        },
        { returnBatchTask: true }
      )
    );
  }

  return tasks;
}

// i18n certified - complete
