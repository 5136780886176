import { AccountInfo, AccountPrivate, PaymentMethod } from "@ollie-sports/models";
import moment from "moment";
import { BatchTask } from "@ollie-sports/firebase";
import _ from "lodash";
import stableStringify from "json-stable-stringify";
import { getUniversalHelpers } from "../helpers";

export function extractFullNameFromAccountInfo(p: { accountInfo: AccountInfo }): string {
  return `${p.accountInfo.firstName} ${p.accountInfo.lastName}`;
}

export function extractInitialsFromAccountInfo(p: { accountInfo: AccountInfo }): string {
  return `${p.accountInfo.firstName[0]}${p.accountInfo.lastName[0]}`;
}

export function extractInitialsFromFirstAndLastName(p: { firstName: string; lastName: string }): string {
  return `${p.firstName[0]}${p.lastName[0]}`;
}

export function shouldShowRatingsPrompt(p: { accountPrivate: AccountPrivate }) {
  const hasEnoughSessions =
    p.accountPrivate.userInteractions?.numberOfSessions && p.accountPrivate.userInteractions.numberOfSessions > 9;
  const hasSeenRatingsPromptWithin4Months =
    p.accountPrivate.userInteractions?.lastRatingsPrompt &&
    Date.now() - p.accountPrivate.userInteractions.lastRatingsPrompt < 1000 * 60 * 60 * 24 * 125;
  if (hasEnoughSessions && !hasSeenRatingsPromptWithin4Months) {
    return true;
  }
  return false;
}

export async function getBatchTasksToUpdateDerivedForAccount(p: { accountId: string }): Promise<BatchTask[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const [teams, accountPrivate, account, selfAthletePlayerBundles] = await Promise.all([
    h.Team.query({ where: [{ accounts: { [p.accountId]: { exists: ["==", true] } } }] }),
    h.AccountPrivate.getDoc(p.accountId),
    h.Account.getDoc(p.accountId),
    h.PlayerBundle.query({ where: [{ managingAccounts: { [p.accountId]: { type: ["==", "selfAthlete"] as any } } }] })
  ]);

  if (!accountPrivate || !account) {
    throw new Error("Unable to find accountPrivate! " + p.accountId);
  }

  const pendingActiveOrgIds = _(teams.docs)
    .map(a => a.orgId)
    .compact()
    .uniq()
    .keyBy(a => a)
    .mapValues(() => true as const)
    .value();

  const tasks: BatchTask[] = [];

  if (stableStringify(accountPrivate.derived?.activeOrgIds || {}) !== stableStringify(pendingActiveOrgIds)) {
    tasks.push(
      await h.AccountPrivate.setPath(
        {
          id: p.accountId,
          pathObj: { derived: { activeOrgIds: true } },
          value: { derived: { activeOrgIds: pendingActiveOrgIds } }
        },
        { returnBatchTask: true }
      )
    );
  }

  let expectedVirtualAthleteAccountInfo = {
    email: account.email,
    firstName: account.firstName,
    lastName: account.lastName,
    phoneNumber: account.phoneNumber,
    profileImageUri: account.profileImageUri,
    profileImageUriSmall: account.profileImageUriSmall,
    suffix: account.suffix
  };

  expectedVirtualAthleteAccountInfo = _.omitBy(expectedVirtualAthleteAccountInfo, _.isUndefined) as any;

  await Promise.all(
    selfAthletePlayerBundles.docs.map(async pb => {
      if (stableStringify(pb.virtualAthleteAccount) !== stableStringify(expectedVirtualAthleteAccountInfo)) {
        tasks.push(
          await h.PlayerBundle.updateShallow(
            { id: pb.id, doc: { virtualAthleteAccount: expectedVirtualAthleteAccountInfo } },
            { returnBatchTask: true }
          )
        );
      }
    })
  );

  return tasks;
}

export function getDefaultPaymentAccount(paymentMethods: PaymentMethod[]) {
  if (paymentMethods.length === 0) {
    return null;
  }
  const defaultPaymentMethod = paymentMethods.find(pm => pm.isDefault);
  if (defaultPaymentMethod) {
    return defaultPaymentMethod;
  }
  return _.orderBy(paymentMethods, "createdAtMS", "desc")[0];
}

// i18n certified - complete
